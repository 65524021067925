import MediaSources from "../../media_sources";
import {parseUrlParams} from "../../../core/library/url";

const PROJECT = process.env.PROJECT || "";
const IsLoc = window.location.hostname.indexOf('localhost') + 1;

// const IsLoc = false;


export const atel = {
    SHOW_LANGS: true,
    BASE_DOMAIN: 'https://www.rt.com',
    APP_CLASS: 'atel',
    PROJECT_NAME: 'atel',
    PROJECT_PREFIX: 'atel',
    LANGS: [
        {title: "English", domain: "http://localhost:3000"},
        {title: "Russian", domain: "http://ru.localhost:3000"}
    ],

    SOCKET: '',

    // settings for menu
    MEDIA: {
        // path for cdn content
        CDN: {
            IMAGES: IsLoc ? "https://arteldoc.com/images" : "https://arteldoc.com/images",
            VIDEO: IsLoc ? "https://arteldoc.com/videos" : "https://arteldoc.com/videos",
            PDF: IsLoc ? "https://arteldoc.com/pdf/" : "https://arteldoc.com/pdf/",
            DOCS: IsLoc ? "https://arteldoc.com/docs/" : "https://arteldoc.com/docs/",
        },
        // available copyright sources
        SOURCES: MediaSources,
        // available upload types
        UPLOAD_AVAILABLE: ['jpg', 'gif', 'png', 'jpeg', 'mp4', 'mp3'],
        // available embedded sources
        EMBEDDED_SOURCES: ['youtube.com', 'facebook.com', 'youtu.be', `tvzvezda.ru`],
        EMEDDED_PREPARE: {
            'youtu.be': (path) => {
                let params = path.split('/');
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${params[params.length - 1]}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'youtube.com': (path) => {
                let p = parseUrlParams(path);
                if (!p.v) return ``;
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'facebook.com': (url) => {
                return (
                    `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            },
            'tvzvezda.ru': (url) => {
                return (
                    `<iframe src="${url}" width="500" height="315" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            }
        },
        PREPARE: (path) => {
            if (path.indexOf('mp4/') + 1 > 0) return `https://video.vm.ru${path.replace('https://files.vm.ru', '')}`;
            if (path.indexOf('vecherka') + 1 === 0) return `https://files.vm.ru/vecherka${path}`;
            return path;
        },
    },
    WORKFLOW: {
        SHOW_SEARCH: true,
        SHOW_LINK: true,
        PREPARE_LINK: (item) => {
            return `link`;
        },
        STATUSES: ['DRAFT', 'READY', 'ARCHIVE', 'PUBLISH'],
        STATUSES_COLOR: {
            'DRAFT': 'gray',
            'READY': 'volcano',
            'ARCHIVE': 'purple',
            'PUBLISH': 'green',
        },
        DEFAULT: 'DRAFT',
        LOCK_FOR: ['article', 'news', 'infographic', 'episode', 'gallery', 'meaning'],
        WORKFLOW_FOR: [
            'episode',
            'article',
            'news',
            'gallery',
            'meaning'
        ],
    },
    WIDGETS: {
        TYPES: ['Courses', 'Masterclass', 'Films', 'Article', 'News', 'Episode', 'Infographic', 'Meaning', 'Trend', 'Banner', 'Polls', 'Stories', 'Category', 'Gallery', 'Author']
    },
    ELASTIC: {
        API: IsLoc ? "http://localhost:9005/api/backend/v1/elastic" : "https://admin.arteldoc.com/api/backend/v1/elastic"
    },
    PROJECT: {
        // project name
        NAME: PROJECT,
        // project default layout
        LAYOUT: "default",
        // project api path
        API: IsLoc ? `http://localhost:9005/api/backend/v1` : `https://admin.arteldoc.com/api/backend/v1`,
        ELASTIC_API: IsLoc ? `http://localhost:9005/elastic` : `https://admin.arteldoc.com/elastic`,
    },

    UPLOAD: {
        "path": IsLoc ? "http://localhost:9005/api/backend/v1/media" : "https://admin.arteldoc.com/api/backend/v1/media"
    },
    // oauth configuration
    OAUTH: {
        // url for oauth domain
        URL: `https://auth.rt.com/`,
        // current project domain
        PROJECT_DOMAIN: `localhost`,
        // current project url path
        // PROJECT_PATH: `http://localhost:3000`,
        PROJECT_PATH: IsLoc ? `http://localhost:3000` : `https://admin.arteldoc.com`,
        // path for oauth api
        API: `https://auth.rt.com/auth`,
        // path for oauth users
        OAUTH_USER_PATH: `https://auth.rt.com/users/`,
        // prefix for authorization tokens
        PREFIX: 'oauth_',
        HEADER: "x-access-token",
    },

    VIEWS: {
        documents: {
            "article": "default_view_vm",
            "author": "authors_view",
            "fauthor": "authors_view",
            "category": "category_view",
            "fcategory": "category_view",
            "tag": "tag_view",
            "courses": "category_view",
            "trend": "trend_view",
            "page": "page_view",
            "show": "show_view",
            "newspaper": "newspaper_view",
            "rnewspaper": "rnewspaper_view",
            "schedule": "schedule_view",
            "arates": 'arates_view',
            "aviews": 'aviews_view',
            "polls": 'poll',
            "mcat": 'anons_view',
            "covid": "covid",
            "tags": "category_view",
            "manufacture": "category_view",
            "country": "country_view",
            "genre": "category_view",
        },
        documents_add: {
            "serials": "AddSerial",
            "cfilms": "AddCFilm",
            "manufacture": "add_category",
            "genre": "add_category",
            "tags": "add_category",
            "courses": "course",
            "article": "add_article_vm",
            "trend": "add_trend",
            "news": "add_news",
            "films": "add_films",
            "author": "add_author",
            "fauthor": "add_author",
            "category": "add_category",
            "fcategory": "add_category",
            "tag": "add_tag",
            "banner": "add_banner",
            "page": "add_page",
            "newspaper": "add_newspaper",
            "rnewspaper": "add_rnewspaper",
            "episode": "add_episode",
            "episodes": "add_episode",
            "battle": "add_battle",
            "schedule": "add_schedule",
            "country": "AddCountry",
            "arates": "arate_add",
            "aviews": "aviews_add",
            "infographic": "infographics_add",
            "polls": "poll_add",
            "mcat": "anons_add",
            "covid": "covid",
            "afisha": "afisha_vm",
            "regionmat": "region_material",
            "mgallery": "mgallery",
            "stories": "stories",
            "masterclass": "masterclass",
            "lessons": "lessons",
            "comments": "AddComments",
            "vcomments": "AddCommentsVideo"
        }
    },
    MENU: [
        {
            'title': 'DASHBOARD',
            'path': '/',
            'component': 'dashboard_index',
            'icon': 'dashboard',
        },
        {
            'title': 'DOCUMENTS',
            'path': '/documents/:type',
            'component': 'default_document',
            'subroutes': [
                {
                    'path': '/documents/:type/:id',
                    'component': 'add_documents',
                },
                {
                    'path': '/documents/:type/:id/preview',
                    'component': 'preview',
                },
                {
                    'path': '/documents/:type/create',
                    'component': 'add_documents',
                },
                {
                    'path': '/archive',
                    'component': 'archive',
                },
                {
                    'path': '/documents/episode/:id/chat',
                    'component': 'episode_chat',
                },
                {
                    path: '/mediagallery',
                    component: 'media_gallery',
                }
            ],
            'icon': 'container',
            'submenu': [
                {
                    title: 'News',
                    path: '/documents/news',
                    icon: 'ordered-list',
                },
                {
                    title: 'MEDIA_GALLERY_LIST',
                    path: '/mediagallery',
                    icon: 'picture',
                },
                {
                    'title': 'Gallery',
                    'path': '/documents/gallery',
                    'icon': 'appstore',
                },
            ]
        },
        {
            'title': 'CATALOG',
            'icon': 'database',
            'submenu': [
                {
                    'title': 'Films',
                    'path': '/documents/cfilms',
                    'icon': 'video-camera'
                },
                {
                    'title': 'Serials',
                    'path': '/documents/serials',
                    'icon': 'video-camera'
                },
                {
                    'title': 'Episodes',
                    'path': '/documents/episodes',
                    'icon': 'video-camera'
                },
            ]
        },
        {
            'title': 'COMMENTS',
            'component': 'default_document',
            'icon': 'container',
            'submenu': [
                {
                    title: 'TEXT_COMMENTS',
                    path: '/documents/comments',
                    icon: 'ordered-list',
                },
                {
                    title: 'VIDEO_COMMENTS',
                    path: '/documents/vcomments',
                    icon: 'camera',
                },
            ]
        },
        {
            "title": "DICTS",
            "icon": "database",
            "submenu": [
                {
                    "title": "Country",
                    "path": "/documents/country",
                    "icon": "global",
                },
                {
                    'title': "MANUFACTURE",
                    "path": "/documents/manufacture",
                    "icon": "setting"
                },
                {
                    'title': "CATEGORIES",
                    "path": "/documents/category",
                    "icon": "ordered-list"
                },
                {
                    'title': "TAGS",
                    "path": "/documents/tags",
                    "icon": "ordered-list"
                },
                {
                    'title': "AUTHORS",
                    "path": "/documents/author",
                    "icon": "user"
                },
                {
                    'title': "GENRES",
                    "path": "/documents/genre",
                    "icon": "ordered-list"
                },
            ]
        },


        {
            title: 'MASTER_CLASS',
            path: '/documents/masterclass',
            icon: 'camera',
        },
        {
            'title': 'LECTURES',
            'icon': 'appstore',
            'submenu': [
                {
                    title: 'COURSES',
                    icon: 'ordered-list',
                    path: '/documents/courses',
                },
                {
                    title: 'LESSONS',
                    icon: 'ordered-list',
                    path: '/documents/lessons',
                }
            ]
        },
        {
            'title': 'FILMS',
            'icon': 'camera',
            'submenu': [
                {
                    'title': 'FILMS_LIST',
                    'icon': 'ordered-list',
                    'path': '/documents/films',
                },
                {
                    'title': 'FILMS_CATEGORIES',
                    'path': '/documents/fcategory',
                    'icon': 'ordered-list',
                }
            ]
        },

        {
            'title': 'Votes',
            'icon': 'notification',
            'path': '/vote',
            'component': 'VoteController'
        },
        {
            'title': 'Requests',
            'icon': 'copy',
            'path': '/requests',
            'subroutes': [
                {
                    'path': '/requests/:type',
                    'component': 'RequestIndex',
                },
            ],
            'submenu': [
                {
                    'title': 'REQUEST_FILM',
                    'icon': 'ordered-list',
                    'path': '/requests/film',
                },
                {
                    'title': 'REQUEST_THEME',
                    'path': '/requests/theme',
                    'icon': 'ordered-list',
                },
                {
                    'title': 'REQUEST_STUDY',
                    'path': '/requests/study',
                    'icon': 'ordered-list',
                },
                {
                    'title': 'REQUEST_PITCHING',
                    'path': '/requests/pitching',
                    'icon': 'ordered-list',
                },
                {
                    'title': 'REQUEST_KINOCLUB',
                    'path': '/requests/kinoclub',
                    'icon': 'ordered-list',
                },
                {
                    'title': 'REQUESTS_FILMS',
                    'path': '/requests/films',
                    'icon': 'ordered-list',
                    'component': 'BasketController',
                }
            ]
        },
        {
            'title': 'BANNERS',
            'path': '/documents/banner',
            'icon': 'layout',
        },
        {
            'title': 'WIDGETS',
            'path': '/widgets',
            'icon': 'appstore',
            'component': 'widget',
            'submenu': [
                {
                    'title': 'WIDGETS_EDITOR',
                    'path': '/widgets',
                    'icon': 'appstore',
                    'component': 'widget',
                },
                {
                    'title': 'WIDGETS_ADS',
                    'path': '/widgets_ads',
                    'icon': 'appstore',
                    'component': 'widget',
                },
            ],
            'subroutes': [
                {
                    'path': '/widgets/:id',
                    'component': 'widget_edit',
                },

            ]
        },
        {
            'title': 'PAGES',
            'path': '/documents/page',
            'icon': 'file-text',
        },
        {
            'title': 'USERS',
            'path': '/users',
            'icon': 'user',
            'subroutes': [
                {
                    'path': '/users/:id',
                    'component': 'users_edit',
                },
                {
                    'path': '/users/groups/add',
                    'component': 'add_group',
                },
                {
                    'path': '/users/groups/:id',
                    'component': 'add_group',
                }
            ],
            'submenu': [
                {
                    'title': 'USERS',
                    'path': '/users',
                    'icon': 'user',
                    'component': 'users_index',
                },
                {
                    'title': 'GROUPS',
                    'icon': 'idcard',
                    'path': '/users/groups',
                    'component': 'groups_index',
                },
                {
                    'title': 'PERMISSIONS',
                    'icon': 'idcard',
                    'path': '/users/permissions',
                    'component': 'permission_index',
                }
            ]
        },
    ],
};

export default atel;
