import { MergeObjects } from './../core/library/objects';
import MediaSources from './media_sources';
import * as Projects from './projects';
import {parseUrlParams} from "../core/library/url";
import PushRegions from './regions';

const PROJECT = process.env.PROJECT || "";


let Config = {
    LOCALE: "ru",
    PROJECT_PREFIX: "test",
    // project config
    PROJECT: {
        // project name
        NAME: PROJECT,
        // project default layout
        LAYOUT: "default",
        // project api path
        API: `http://localhost:8086/api/backend`,
    },

    // oauth configuration
    OAUTH: {
        // url for oauth domain
        URL: ``,
        // current project domain
        PROJECT_DOMAIN: `localhost:3000`,
        // current project url path
        PROJECT_PATH: `http://localhost:3000`,
        // path for oauth api
        API: ``,
        // path for oauth users
        OAUTH_USER_PATH: ``,
        // prefix for authorization tokens
        PREFIX: 'oauth_',
        HEADER: "x-access-token"
    },
    // default date format
    DATE_FORMAT: "DD-MM-YYYY HH:mm",
    // configuration for slug plugin
    SLUG: {
        delimiter: '-',
        prefix: ''
    },

    // configuration for media
    MEDIA: {
        // path for cdn content
        CDN: {
            IMAGES: "https://cdni.rbth.com/rbthmedia/images",
            VIDEO: "https://cdni.rbth.com/rbthmedia/video"
        },
        // available copyright sources
        SOURCES: MediaSources,
        // available upload types
        UPLOAD_AVAILABLE: ['jpg','gif','png','jpeg','mp4','mp3'],
        // available embedded sources
        EMBEDDED_SOURCES: ['youtube.com', 'facebook.com', 'youtu.be'],
        EMEDDED_PREPARE: {
            'youtu.be': (path) => {
                let params = path.split('/');
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${params[params.length - 1]}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'youtube.com': (path) => {
                let p = parseUrlParams(path);
                if (!p.v) return ``;
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'facebook.com': (url) => {
                return (
                    `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            },
        },
    },

    DOCUMENTS: {
        STATUS: ["published", "unpublished"],
        MOVE_TYPES: ['News', 'Article', "Meaning", "Embargo"],
    },
    EDITOR: {
        KEY: "clzzivqs4p1bb1eoei67nmbzwwzio5uv0w69ew52cc8ex5s7",
        SETTINGS: {
            language: 'ru',
        },
    },
    MAPS: {
        KEY: "e70f3b03-0677-45b8-911f-830daf383e37",
    },
    ELASTIC: {
        API: "http://localhost:8009/elastic"
    },
    MEDIA_DATABASE_TYPES: ['Image', 'Video', 'ExternalVideo', 'Code'],
    MEDIA_AVAILABLE_TYPES: '.jpg,.gif,.png,.jpeg,.mp4,.mp3',
    MEDIA_AVAILABLE_SOURCES: ['youtube', 'vimeo', 'facebook'],
    WIDGETS: {
        TYPES: ['Article', 'News', 'Episode', 'Infographic','Meaning', 'Trend', 'Banner', 'Polls']
    },
    REGIONS: PushRegions,
    PHOTO_AUTHORS: [
        `mos.ru / Официальный сайт мэра Москвы`,
        `mos.ru / Максим Денисов`,
        `mos.ru / Юлия Иванко`,
        `mos.ru / Евгений Самарин`,
        `Пресс-служба мэра и правительства Москвы / Владимир Новиков`,
        `Пресс-служба мэра и правительства Москвы / Максим Мишин`,
        `Пресс-служба мэра и правительства Москвы / Евгений Самарин`,
        `Сайт Сергея Собянина`,
        `vk / Сергей Собянин`,
        `Twitter / Сергей Собянин`,
        `Facebook / Сергей Собянин`,
        `АГН Москва`,
        `Сергей Ведяшкин / АГН Москва`,
        `Александр Авилов / АГН Москва`,
        `Кирилл Зыков / АГН Москва`,
        `Сергей Киселев / АГН Москва`,
        `Андрей Никеричев / АГН Москва`,
        `Софья Сандурская / АГН Москва`,
        `Мобильный репортер / АГН Москва`,
        `Денис Воронин / АГН Москва`,
        `ТАСС / Имя Фамилия`,
        `РИА Новости / Имя Фамилия`,
        `Пелагия Замятина / Вечерняя Москва`,
        `Антон Гердо / Вечерняя Москва`,
        `Алексей Орлов / Вечерняя Москва`,
        `Светлана Колоскова / Вечерняя Москва`,
        `Александр Кожохин / Вечерняя Москва`,
        `Наталия Нечаева / Вечерняя Москва`,
        `Наталья Феоктистова / Вечерняя Москва`,
        `Youtube /`,
        `Twitter /`,
        `Пресс-служба главы Республики Крым`,
        `Пресс-служба губернатора Московской области`,
        `Пресс-служба Москомархитектуры`,
        `Пресс-служба Госдумы`,
        `Владимир Новиков / Вечерняя Москва`,
        `Наталия Белова / Вечерняя Москва`,
        `Telegram / Дептранс Москвы`,
        `Telegram / Baza`,
        `Telegram / Дептранс. Оперативно`,
        `Telegram-канал / Подъем!`,
        `Telegram / Mash`,
        `Оперштаб`,
        `Стопкоронавирус.рф`,
        `Денис Гришкин /  АГН Москва`,
        `Владимир Смоляков / Вечерняя Москва`,
        `Администрация президента РФ`,
        `Игорь Генералов / Вечерняя Москва`,
        'Вечерняя Москва',
        'Андрей Любимов / АГН Москва',
        'Игорь Иванко / АГН Москва',
        'Пресс-служба Совфеда',
        'Минобороны РФ',
        'Правительство РФ',
        'МИД РФ',
        'МЧС',
        'СК РФ',
        'Pixabay',
        'Pexels',
        'Facebook /',
        'Telegram /',
        'Анна Быкова / Вечерняя Москва',
        'Руслан Давлетшин / Вечерняя Москва',
        'Анна Малакмадзе / Вечерняя Москва',
        'Антон Кардашов / АГН Москва',
        'Музей Победы',
        'Департамент труда и соцзащиты города Москвы',
        'Instagram /',
        'Telegram / Городское хозяйство Москвы',
        'Главархив Москвы',
        'Департамент ГОЧСиПБ',
        'Артем Соболев / Вечерняя Москва',
        'Мария Лукина / Вечерняя Москва'
    ],
    REGION_SITES: {
        'ЦАО': [
            'caoinform.moscow',
            'arbatskievesti.moscow',
            'basmannievesti.moscow',
            'gazetazamoskvoreche.moscow',
            'krasnoeselo.moscow',
            'meschanskayasloboda.moscow',
            'napresne.moscow',
            'vestitaganka.moscow',
            'karetniyryad.moscow',
            'gazetahamovniki.moscow',
            'gazetayakimanka.moscow',
        ],
        'ЮАО': [
            'ugorizont.ru',
            'gazeta-na-varshavke-chertanovocentr.ru',
            'gazeta-chertanovo-juzhnoe.ru',
            'gazeta-na-varshavke-chertanovo-severnoe.ru',
            'gazeta-tsaricinsky-vestnik.ru',
            'gazeta-orehovo-borisovo-juzhnoe.ru',
            'gazeta-borisovskie-prudi.ru',
            'gazeta-na-varshavke-nagorny.ru',
            'gazeta-nagatinsky-zaton.ru',
            'gazeta-nagatino-sadovniki.ru',
            'gazeta-nashe-moskvoreche-saburovo.ru',
            'gazeta-novosti-zyablikovo.ru',
            'gazeta-moy-rayon-donskoy.ru',
            'gazeta-danilovsky-vestnik.ru',
            'gazeta-brateevo.ru',
            'gazeta-obozrenie-birulevo-zapadnoe.ru',
            'gazeta-birulevo-vostochnoe.ru',
        ],
        'ТиНАО': [
            'newokruga.ru',
        ],
    },
    REGION_SITES_CATEGORIES: [
        'Общество',
        'Власть',
        'Коронавирус',
        'Важное',
    ],
    REGION_SITES_CATEGORIES_SYSTEM: [
        '_Информационный материал',
        '_РР',
    ],
    REGION_SITES_TAGS: [
        'Москва',
        'официально',
        'мэр',
        'мэр Москвы',
        'Сергей Собянин',
    ],
    SPECIAL_PROJECT_TYPES: ['Фотовыставка', 'Спец. репортаж', 'Выставка', 'Специальный материал'],
    CROPPER: {
        WIDTH: 1200,
        HEIGHT: 675,
    }
};

if(Projects[PROJECT]) {
    Config = MergeObjects(Config, Projects[PROJECT]);
}

window.APP_CONFIG = {
    PROJECT: Config.PROJECT,
    MEDIA: Config.MEDIA,
};

export default Config;


